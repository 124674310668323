import { getSchemeUrl } from "@/utils/toolsApi";
import { env } from "md-base-tools/env";
export default {
  data() {
    return {
      type: "",
      id: ""
    };
  },
  computed: {
    des() {
      if (this.appJumpEnabled()) {
        return '查看详情';
      }
      if (this.minaJumpEnabled()) {
        return '查看详情请打开微信小程序';
      }
      return '暂不支持查看';
    },
    btn_name() {
      if (this.appJumpEnabled()) {
        return '打开详情';
      }
      if (this.minaJumpEnabled()) {
        return '打开小程序';
      }
      return '离开页面';
    }
  },
  mounted() {
    this.type = this.$route.query.type || "";
    this.id = this.$route.query.id || "";
  },
  methods: {
    // 是否有对应的APP原生页-如果有且内嵌APP-点击优先跳原生页
    appJumpEnabled() {
      let enaled = false;
      if (env.isModianIos() || env.isModianAndroid()) {
        switch (this.type) {
          case "draw":
            {
              enaled = true;
            }
            break;
        }
      }
      return enaled;
    },
    // 是否有对应的小程序原生页-如果有且非内嵌APP-点击优先跳小程序原生页
    minaJumpEnabled() {
      let enaled = false;
      switch (this.type) {
        case "draw":
          {
            enaled = true;
          }
          break;
      }
      return enaled;
    },
    // 获取type对应的APP内链及小程序path路径
    getJumpConfig() {
      let config = {};
      switch (this.type) {
        case "draw":
          // 抽奖详情
          config = {
            mina_path: "/pages/index/index",
            mina_query: `nextUrl=pages/prize/detail/detail&activity_id=${this.id}`,
            md_link: `md://prizeDetail?id=${this.id}`
          };
          break;
      }
      return config;
    },
    handleBtnClick() {
      if (this.appJumpEnabled() || this.minaJumpEnabled()) {
        let jumpConfig = this.getJumpConfig();
        // eslint-disable-next-line no-undef
        graft.app({
          functionName: "openController",
          functionParams: jumpConfig.md_link,
          webFun: () => {
            if (this.minaJumpEnabled) {
              //打开小程序
              this.openWXmina(jumpConfig.mina_path, jumpConfig.mina_query);
            }
          }
        });
      } else {
        // eslint-disable-next-line no-undef
        graft.app({
          functionName: "closeController",
          webFun: () => {
            window.history.go(-1);
          }
        });
      }
    },
    async openWXmina(path, query) {
      const url = await this.getScheme(path, query);
      if (url) {
        location.href = url;
      }
    },
    async getScheme(path, query) {
      const param = {
        path: path,
        query: query
      };
      const option = {
        jump_wxa: JSON.stringify(param)
      };
      const url = await getSchemeUrl(option);
      if (!url) return false;
      return url;
    }
  }
};